/* eslint-disable react/prop-types */
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import './footer.scss';

const FooterLink = ({ title, link }) => {
  const [isHover, setHover] = useState(false);
  const style = useSpring({ width: isHover ? '100%' : '0%' });

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ marginRight: 40 }}
    >
      <Link to={link}>{title}</Link>
      <animated.div style={{ ...style, borderBottom: 'solid 2px #637ce8' }} />
    </div>
  );
};

const Footer = () => (
  <footer className="Footer">
    <div className="Footer__Links">
      <FooterLink link="/what-we-do/" title="what we do" />
      <FooterLink link="/our-work/" title="our work" />
      <FooterLink link="/program/" title="program" />
      <FooterLink link="/mission/" title="mission" />
      {/* <FooterLink link="/faq/" title="FAQ" /> */}
      <FooterLink link="/contact-us/" title="contact us" />
    </div>
    <div className="Footer__copyright">2021 Launch Deck, LLC. All Rights Reserved.</div>
  </footer>
);

export default Footer;
