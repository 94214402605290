/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import Sticky from 'react-sticky-el';

import './header.scss';

const logo = require('../../images/logo-ld-logo-on-light@3x.png');
const miniLogo = require('../../images/logo.png');
const logoDark = require('../../images/logo-ld-logo-on-dark@3x.png');
const menuIcon = require('../../images/icon-menu@3x.png');
const menuWhiteIcon = require('../../images/menu-white@3x.png');
const closeIcon = require('../../images/close@3x.png');

const isActive = ({ isCurrent }) => (isCurrent ? { className: 'Header__Links-active' } : {});

const HeaderLink = ({ backgroundColor, title, link }) => {
  const [isHover, setHover] = useState(false);
  const [isFirst, setFirst] = useState(true);

  useEffect(() => {
    setFirst(false);
  }, []);

  const style = isHover
    ? useSpring({
      width: '100%', from: { width: '0' },
    })
    : useSpring({ immediate: isFirst, width: '0', from: { width: '100%' } });

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ marginLeft: 40 }}
    >
      <Link
        getProps={isActive}
        className={backgroundColor !== 'white' ? 'Header__Links_ItemWhite' : 'Header__Links_Item'}
        to={link}
      >
        {title}
      </Link>
      <animated.div style={{ ...style, borderBottom: 'solid 2px #637ce8' }} />
    </div>
  );
};

const Header = ({ backgroundColor }) => {
  const [showMenu, toggleShowMenu] = useState(false);
  // const [isLogoHovered, setHoverLogo] = useState(false);
  // const [isMenuHovered, setHoverMenu] = useState(false);
  const [isScrollTop, setScroll] = useState(false);
  // const [menuClicked, setClick] = useState(false);

  const logoTransitions = useTransition(isScrollTop, null, {
    from: {
      position: 'absolute', opacity: 0, left: 120,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const menuTransitions = useTransition(isScrollTop, null, {
    from: {
      position: 'absolute', opacity: 0, right: 120,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const mobileMenuStyle = useSpring({ opacity: showMenu ? 1 : 0 });

  const logoStyle = useSpring({
    width: 200, height: 65, top: 48,
  });

  const miniLogoStyle = useSpring({
    width: 32, height: 32, top: 32,
  });

  const menuStyle = useSpring({
    width: 32, height: 32, top: 32,
  });

  // const menuLinkStyle = useSpring({ opacity: menuClicked ? 1 : 0 });

  const defaultLogo = (key, props) => (backgroundColor !== 'white'
    ? <animated.img style={{ ...logoStyle, ...props }} key={key} src={logoDark} alt="logo" className="Header__Logo" />
    : <animated.img key={key} style={{ ...logoStyle, ...props }} src={logo} alt="logo" className="Header__Logo" />);

  const miniLogoEl = (key, props) => <animated.img key={key} style={{ ...miniLogoStyle, ...props }} src={miniLogo} alt="logo" className="Header__Logo" />;

  const handleScroll = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setScroll(true);
    } else {
      setScroll(false);
      // close menu
      // setClick(false);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      handleScroll();
    };
  }, []);

  return (
    <Sticky className="Header__Sticky">
      <header className="Header" style={{ backgroundColor: isScrollTop ? 'transparent' : backgroundColor }}>
        <div className="Header__Desktop">
          <div>
            <Link to="/">
              {
                logoTransitions.map(({ item, key, props }) => (item
                  ? miniLogoEl(key, props) : defaultLogo(key, props)))
              }
            </Link>
          </div>
          {
            menuTransitions.map(({ item, key, props }) => (item
              ? (
                <animated.img
                  key={key}
                  style={{
                    ...menuStyle, cursor: 'pointer', margin: 0, ...props,
                  }}
                  // onMouseEnter={() => setHoverMenu(true)}
                  // onMouseLeave={() => setHoverMenu(false)}
                  src={menuIcon}
                  alt="menu"
                  // onClick={() => setClick(!menuClicked)}
                  onClick={() => toggleShowMenu(true)}
                />
              ) : (
                <animated.div className="Header__Links" key={key} style={{ ...props, top: 68 }}>
                  <HeaderLink backgroundColor={backgroundColor} title="what we do" link="/what-we-do/" />
                  <HeaderLink backgroundColor={backgroundColor} title="our work" link="/our-work/" />
                  <HeaderLink backgroundColor={backgroundColor} title="program" link="/program/" />
                  <HeaderLink backgroundColor={backgroundColor} title="mission" link="/mission/" />
                  {/* <HeaderLink backgroundColor={backgroundColor} title="FAQ" link="/faq/" /> */}
                  <HeaderLink backgroundColor={backgroundColor} title="contact us" link="/contact-us/" />
                </animated.div>
              )))
          }
          {/* menu link desktop */}
          {/* <animated.div className="Header__Links"
          style={{ ...menuLinkStyle, top: 68, marginRight: 80 }}>
            <HeaderLink backgroundColor={backgroundColor} title="what we do" link="/what-we-do/" />
            <HeaderLink backgroundColor={backgroundColor} title="our work" link="/our-work/" />
            <HeaderLink backgroundColor={backgroundColor} title="program" link="/program/" />
            <HeaderLink backgroundColor={backgroundColor} title="mission" link="/mission/" />
            <HeaderLink backgroundColor={backgroundColor} title="contact us" link="/contact-us/" />
        </animated.div> */}
        </div>
        <div className="Header__Mobile">
          <div className="Header__Mobile__Container">
            <Link to="/">
              {backgroundColor !== 'white' ? <img src={logoDark} alt="logo" className="Header__Mobile__Logo" /> : <img src={logo} alt="logo" className="Header__Mobile__Logo" />}
            </Link>
            <img src={backgroundColor === 'white' ? menuIcon : menuWhiteIcon} alt="menu" style={{ width: 24, height: 24 }} onClick={() => toggleShowMenu(true)} />
          </div>
        </div>
        {showMenu && (
        <animated.div style={mobileMenuStyle} className="Header__Mobile__Menu">
          <img src={closeIcon} alt="" style={{ width: 24, height: 24 }} onClick={() => toggleShowMenu(false)} />
          <Link getProps={isActive} className="Header__Mobile__Menu__Link" to="/what-we-do/">what we do</Link>
          <Link getProps={isActive} className="Header__Mobile__Menu__Link" to="/our-work/">our work</Link>
          <Link getProps={isActive} className="Header__Mobile__Menu__Link" to="/program/">program</Link>
          <Link getProps={isActive} className="Header__Mobile__Menu__Link" to="/mission/">mission</Link>
          {/* <Link getProps={isActive} className={backgroundColor !== 'white'
                ? 'Header__Links_ItemWhite' : 'Header__Links_Item'} to="/faq/">FAQ</Link> */}
          <Link getProps={isActive} className="Header__Mobile__Menu__Link" to="/contact-us/">contact us</Link>
        </animated.div>
        )}
      </header>
    </Sticky>
  );
};

Header.propTypes = {
  // siteTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  // siteTitle: '',
  backgroundColor: 'white',
};

export default Header;
