/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';

import Header from './header/header';
import Footer from './footer/footer';
import './layout.scss';

const nextBlue = require('../images/icon-arrow-next@3x.png');

const Layout = ({
  children, backgroundColor, extra, hasFooter,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [startBottomText, setStart] = useState(false);

  const onChange = (isVisible) => {
    if (!startBottomText) setStart(isVisible);
  };

  return (
    <div className="Layout" id="layoutContainer">
      <Header backgroundColor={backgroundColor} siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      {extra && (
      <VisibilitySensor onChange={onChange}>
        <div>
          <div className="Letsbuild">
            Let&apos;s build a great
            <br />
            product together.
          </div>
          <div>
            <Link to="/contact-us/" className="Letstalk">
              Let’s talk
              <img src={nextBlue} alt="" />
            </Link>
          </div>
        </div>
      </VisibilitySensor>
      )}
      {hasFooter && <Footer />}
    </div>
  );
};

// <VisibilitySensor onChange={onChange}>
//         <div>
//           <animated.div style={{ ...textStyle(0), opacity:
// startBottomText ? 1 : 0 }} className="Letsbuild">
//             Let&apos;s build a great
//             <br />
//             product together.
//           </animated.div>
//           <animated.div style={{ ...textStyle(1), opacity: startBottomText ? 1 : 0 }}>
//             <Link to="/contact-us/" className="Letstalk">
//               Let’s talk
//               <img src={nextBlue} alt="" />
//             </Link>
//           </animated.div>
//         </div>
//       </VisibilitySensor>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  extra: PropTypes.bool,
  hasFooter: PropTypes.bool,
};
Layout.defaultProps = {
  backgroundColor: 'white',
  extra: true,
  hasFooter: true,
};

export default Layout;
